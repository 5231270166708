import { render, staticRenderFns } from "./CardView.vue?vue&type=template&id=61da2bb2&scoped=true"
import script from "./CardView.vue?vue&type=script&lang=js"
export * from "./CardView.vue?vue&type=script&lang=js"
import style0 from "./CardView.vue?vue&type=style&index=0&id=61da2bb2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61da2bb2",
  null
  
)

export default component.exports